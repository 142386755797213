var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":'History - '+_vm.details.booking_no,"show":_vm.showModal,"color":"info","size":"lg"},on:{"update:show":function($event){_vm.showModal=$event}}},[_c('div',{staticClass:"pl-3 pr-3 pb-3"},[_c('CRow',{staticClass:"mb-2"},[_c('CCol',{attrs:{"lg":"12"}},[_c('div',{staticClass:"table-responsive-sm"},[_c('CDataTable',{attrs:{"items":_vm.details.booking_history,"fields":[
							{
								key: 'status', 
								label: 'Description'
							},
							{
								key: 'users_id', 
								label: 'Users'
							},
							{
								key: 'created_at', 
								label: 'Transaction Date'
							},
							{
								key: 'remarks', 
								label: 'Remarks'
							}
						],"hover":"","small":"","fixed":"","bordered":"","outlined":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
						var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","text-transform":"capitalize"}},[_vm._v(" "+_vm._s(item.status)),_c('br'),_c('small',[_vm._v(_vm._s(item.action_timestamp ? _vm.moment.unix(item.action_timestamp).format("MMM. DD, YYYY hh:mm A"): 'N/A'))])])]}}])})],1)])],1)],1),_c('div',{staticClass:"w-100",attrs:{"slot":"footer"},slot:"footer"},[_c('CButton',{staticClass:"ml-1 mr-1 float-right",staticStyle:{"border-radius":".2rem","color":"white"},attrs:{"color":"danger"},on:{"click":function($event){return _vm.$emit('showModal', false)}}},[_vm._v(" Close ")])],1),_c('div',{staticClass:"w-100",attrs:{"slot":"footer"},slot:"footer"})])}
var staticRenderFns = []

export { render, staticRenderFns }